var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"grid-scrollable grid-left"},[_c('div',{staticClass:"grid-headers"},[_c('div',{staticClass:"grid-header employee-col"},[_c('div',{staticClass:"grid-header-text"},[_c('div',{staticClass:"grid-header-text-content"},[_vm._v(" "+_vm._s(_vm.$t("global.employee"))+" ")])])]),_c('div',{staticClass:"grid-header total-hour-col"},[_c('div',{staticClass:"grid-header-text"},[_c('div',{staticClass:"grid-header-text-content"},[_vm._v(" "+_vm._s(_vm.$t("global.total_hours"))+" ")])])])]),_c('div',{staticClass:"grid-body"},_vm._l((_vm.employees),function(employee,index){return _c('div',{key:index,staticClass:"grid-row"},[_c('div',{staticClass:"grid-row-col"},[_c('div',{staticClass:"grid-cell employee-cell"},[_c('employee-profile',{attrs:{"employee":employee}})],1),_c('div',{staticClass:"grid-cell total-hour-cell"},[_vm._v(" "+_vm._s(_vm.totalHours[index])+" "+_vm._s(_vm.$t("global.hour"))+" / "+_vm._s(_vm.$t("global.month"))+" ")])])])}),0)]),_c('div',{staticClass:"grid-scrollable grid-right"},[_c('div',{staticClass:"grid-headers"},[_c('div',{staticClass:"grid-header timeaxis-col"},[_c('div',{staticClass:"grid-header-row grid-header-row-top"},[_c('div',{staticClass:"timeaxis-cell"},[_vm._v(" "+_vm._s(_vm.dateLabel)+" ")])]),_c('div',{staticClass:"grid-header-row grid-header-row-bottom"},_vm._l((_vm.calendar),function(item,index){return _c('div',{key:index,class:['timeaxis-cell', { weekday: item.isWeekend }]},[(item.holiday)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({style:({
                      color: item.holiday.color,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      padding: '0 1px'
                    })},on),[_vm._v(" "+_vm._s(item.holiday.name)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.holiday.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.date)+" "+_vm._s(item.day)+" ")])],1)}),0)])]),_c('div',{staticClass:"grid-body"},_vm._l((_vm.events),function(workplans,index){return _c('div',{key:index,staticClass:"grid-row"},[_c('div',{staticClass:"grid-row-col"},_vm._l((workplans),function(workplan,index2){return _c('div',{key:index2,class:['grid-cell timeaxis-cell', { 'empty-cell': !workplan }]},[(workplan)?_c('timeaxis-item',{attrs:{"workplan":workplan}}):_c('v-btn',{attrs:{"icon":"","large":"","color":"primary"},on:{"click":function($event){return _vm.onCreateShiftBtnClick(index2, index)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)}),0)])}),0)])]),_c('shift-create-dialog',{attrs:{"employees":_vm.employees,"employee":_vm.selectedEmployee,"date":_vm.selectedDate,"holidays":_vm.holidays},model:{value:(_vm.createDialogStatus),callback:function ($$v) {_vm.createDialogStatus=$$v},expression:"createDialogStatus"}}),_c('shift-detail-dialog',{attrs:{"shift":_vm.currentShift,"workplan":_vm.currentWorkplan},on:{"close":_vm.onDetailDialogClose},model:{value:(_vm.detailDialogStatus),callback:function ($$v) {_vm.detailDialogStatus=$$v},expression:"detailDialogStatus"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }