<template>
  <div class="grid-container">
    <div class="grid">
      <div class="grid-scrollable grid-left">
        <div class="grid-headers">
          <div class="grid-header employee-col">
            <div class="grid-header-text">
              <div class="grid-header-text-content">
                {{ $t("global.employee") }}
              </div>
            </div>
          </div>
          <div class="grid-header total-hour-col">
            <div class="grid-header-text">
              <div class="grid-header-text-content">
                {{ $t("global.total_hours") }}
              </div>
            </div>
          </div>
        </div>
        <div class="grid-body">
          <div class="grid-row" v-for="(employee, index) in employees" :key="index">
            <div class="grid-row-col">
              <div class="grid-cell employee-cell">
                <employee-profile :employee="employee" />
              </div>
              <div class="grid-cell total-hour-cell">
                {{ totalHours[index] }} {{ $t("global.hour") }} / {{ $t("global.month") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-scrollable grid-right">
        <div class="grid-headers">
          <div class="grid-header timeaxis-col">
            <div class="grid-header-row grid-header-row-top">
              <div class="timeaxis-cell">
                {{ dateLabel }}
              </div>
            </div>
            <div class="grid-header-row grid-header-row-bottom">
              <div
                :class="['timeaxis-cell', { weekday: item.isWeekend }]"
                v-for="(item, index) in calendar"
                :key="index"
              >
                <v-tooltip bottom v-if="item.holiday">
                  <template #activator="{on}">
                    <span
                      v-on="on"
                      :style="{
                        color: item.holiday.color,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        padding: '0 1px'
                      }"
                    >
                      {{ item.holiday.name }}
                    </span>
                  </template>
                  {{ item.holiday.name }}
                </v-tooltip>
                <span v-else> {{ item.date }} {{ item.day }} </span>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-body">
          <div class="grid-row" v-for="(workplans, index) in events" :key="index">
            <div class="grid-row-col">
              <div
                :class="['grid-cell timeaxis-cell', { 'empty-cell': !workplan }]"
                v-for="(workplan, index2) in workplans"
                :key="index2"
              >
                <timeaxis-item v-if="workplan" :workplan="workplan" />
                <v-btn v-else icon large color="primary" @click="onCreateShiftBtnClick(index2, index)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <shift-create-dialog
      v-model="createDialogStatus"
      :employees="employees"
      :employee="selectedEmployee"
      :date="selectedDate"
      :holidays="holidays"
    />
    <shift-detail-dialog
      v-model="detailDialogStatus"
      :shift="currentShift"
      :workplan="currentWorkplan"
      @close="onDetailDialogClose"
    />
  </div>
</template>

<script>
  import { FILTER_HOLIDAYS } from "../../gql";

  export default {
    name: "IdCalendar",
    components: {
      TimeaxisItem: () => import("./TimeaxisItem"),
      ShiftCreateDialog: () => import("../ShiftCreateDialog"),
      ShiftDetailDialog: () => import("../ShiftDetailDialog.vue")
    },
    props: {
      date: String,
      employees: Array,
      totalHours: Array,
      workplans: Array
    },
    data: vm => ({
      calendar: [],
      events: [],
      createDialogStatus: false,
      detailDialogStatus: false,
      selectedEmployee: null,
      selectedDate: null,
      holidays: [],
      currentShift: null,
      currentWorkplan: null
    }),
    computed: {
      currentYear() {
        return this.$moment(this.date, "YYYY-MM").format("YYYY");
      },
      currentMonth() {
        return this.$moment(this.date, "YYYY-MM").format("MM");
      },
      dateLabel() {
        return this.currentYear && this.currentMonth
          ? `${this.$moment(this.date, "YYYY-MM").format("MMMM")} / ${this.currentYear}`
          : "";
      }
    },
    watch: {
      date(val) {
        if (!this.employees.length) {
          this.preInitCalendar(this.currentYear, this.currentMonth);
        }
      },
      async workplans(val) {
        await this.initCalendar(this.currentYear, this.currentMonth);
        this.events = [];
        val.forEach(workplans => {
          const items = [];
          this.calendar.forEach(item => {
            const itemDate = `${this.currentYear}-${this.currentMonth}-${item.date}`;
            const workplan = workplans.find(({ date }) => date === itemDate);
            items.push(workplan);
          });
          this.events.push(items);
        });
      }
    },
    methods: {
      async fetchHolidays() {
        await this.$apollo
          .query({
            query: FILTER_HOLIDAYS,
            fetchPolicy: "no-cache",
            variables: {
              criteria: {
                beginDate: this.$moment(`${this.currentYear}-${this.currentMonth}`)
                  .startOf("month")
                  .format("YYYY-MM-DD"),
                endDate: this.$moment(`${this.currentYear}-${this.currentMonth}`)
                  .endOf("month")
                  .format("YYYY-MM-DD")
              }
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.holidays = data.filterHolidays;
            }
          })
          .catch(console.error);
      },
      onCreateShiftBtnClick(colIndex, rowIndex) {
        this.selectedEmployee = this.employees[rowIndex].id;
        this.selectedDate = `${this.currentYear}-${this.currentMonth}-${this.calendar[colIndex].date}`;
        this.createDialogStatus = true;
      },
      getHoliday(date) {
        return this.holidays.find(item => item.date === date);
      },
      async initCalendar(year, month) {
        await this.fetchHolidays();
        this.calendar = [];
        const daysInMonth = this.$moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
        for (let i of Array(daysInMonth).keys()) {
          const date = (i + 1).toString().padStart(2, "0");
          const currDate = this.$moment(`${year}-${month}-${date}`).format("YYYY-MM-DD");
          const holiday = this.getHoliday(currDate);
          this.calendar.push({
            day: this.$moment(currDate).format("ddd"),
            holiday: holiday,
            isWeekend: [0, 6].includes(this.$moment(currDate).day()),
            date
          });
        }
      },
      preInitCalendar(year = this.$moment().format("YYYY"), month = this.$moment().format("MM")) {
        this.calendar = [];
        const daysInMonth = this.$moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
        for (let i of Array(daysInMonth).keys()) {
          const date = (i + 1).toString().padStart(2, "0");
          const currDate = this.$moment(`${year}-${month}-${date}`).format("YYYY-MM-DD");
          const holiday = this.getHoliday(currDate);
          this.calendar.push({
            day: this.$moment(currDate).format("ddd"),
            holiday: holiday,
            isWeekend: [0, 6].includes(this.$moment(currDate).day()),
            date
          });
        }
      },
      openShiftDetail(shift, workplan) {
        this.currentShift = shift;
        this.currentWorkplan = workplan;
        this.detailDialogStatus = true;
      },
      onDetailDialogClose() {
        this.currentShift = null;
        this.currentWorkplan = null;
      }
    },
    created() {
      this.preInitCalendar();
    },
    mounted() {
      this.$eventBus.$on("open:shift-detail", this.openShiftDetail);
    },
    beforeDestroy() {
      this.$eventBus.$off("open:shift-detail");
    }
  };
</script>

<style lang="scss" scoped>
  .grid-container {
    height: calc(100vh - 320px);
    overflow: auto;
    position: relative;
    border: 1px solid #ededed;
  }
  .grid {
    min-height: 100%;
    display: inline-flex;
    flex-direction: row;
    .grid-scrollable {
      display: flex;
      flex-direction: column;
    }
    .grid-left {
      width: 401px;
      border-right: 1px solid #e0e0e0;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 3;
      background-color: #fff;
    }
    .grid-right {
      flex: 1 1 0%;
    }
    .grid-headers {
      min-width: 100%;
      display: inline-flex;
      flex-flow: row nowrap;
      align-items: stretch;
      line-height: normal;
      z-index: 2;
      position: sticky;
      top: 0;
      .grid-header {
        padding: 0 0.5em;
        cursor: pointer;
        user-select: none;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        flex-shrink: 0;
        overflow: hidden;
        background: #f9f9f9;
        color: #565656;
        outline: none;
        text-transform: none;
        border-bottom: 1px solid #e0e0e0;
        &:not(:last-child) {
          border-right: 1px solid #e0e0e0;
        }
        &-text {
          border-bottom: none;
          display: flex;
          flex-direction: row;
          align-items: center;
          overflow: hidden;
          user-select: none;
          padding: 1em 0;
          white-space: nowrap;
          font-weight: inherit;
          &-content {
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1 1 0;
            width: 0;
            font-size: 1em;
          }
        }
        &-row {
          display: flex;
          flex-direction: row;
          flex: 1;
          .timeaxis-cell {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #565656;
            border-bottom: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            overflow: hidden;
            transition: background-color 0.2s;
            font-size: 1em;
            font-weight: inherit;
            padding: 0.5em 0;
            white-space: nowrap;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            &:last-child {
              border-right: none;
            }
          }
          &-top {
            .timeaxis-cell {
              width: 100%;
              height: 35px;
            }
          }
          &-bottom {
            .timeaxis-cell {
              width: 60px;
              height: 35px;
              border-bottom: none;
            }
            .timeaxis-cell.weekday {
              background-color: #f7efef;
            }
          }
        }
      }
      .employee-col {
        min-width: 60px;
        flex-basis: 240px;
        height: 71px;
      }
      .total-hour-col {
        min-width: 60px;
        flex-basis: 160px;
        height: 71px;
      }
      .timeaxis-col {
        min-width: 60px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        user-select: none;
        padding: 0;
        flex-grow: unset;
        background-color: #f9f9f9;
        border-right: 0;
        border-right-color: transparent;
      }
    }
    .grid-body {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      .grid-row {
        display: flex;
        .grid-row-col {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          border-bottom: 1px solid #ededed;
          .grid-cell {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            font-weight: 300;
            padding: 12px;
            overflow: hidden;
            white-space: nowrap;
            flex-shrink: 0;
            text-overflow: ellipsis;
            &:not(:last-child) {
              border-right: 1px solid #ededed;
            }
          }
          .employee-cell {
            width: 240px;
            justify-content: flex-start;
          }
          .total-hour-cell {
            width: 160px;
            justify-content: flex-start;
          }
          .timeaxis-cell {
            width: 60px;
            align-items: flex-start;
          }
          .empty-cell {
            align-items: center;
          }
        }
      }
    }
  }
</style>
